<template>
  <div ref="recordsContainer" class="records-container" @click="handleRecordBgClick">
    <div class="records-divider" @mousedown="handleDividerDown"></div>
    <div class="records-header">
      <div class="title-records">Records ({{ recordTotal }})</div>
      <div class="record-btn-holder" v-if="recordCount > 0">
        <div class="record-btn csv-button" id='csv-button' @click="handleCSVDownload">CSV ({{ recordCount }})</div>
        <div class="record-btn print-button" id='print-button' @click="handlePrintScreen">PRINT ({{ recordCount }})</div>
      </div>
    </div>
    <RecordsTable class="table-view-records-table" />
  </div>
</template>

<script>
import RecordsTable from '@/components/record_list_components/RecordsTable';
import { useSprinklerStore } from '@/store/SprinklerStore';
import { usePrintStore } from '@/store/PrintStore';
import { useSettingsStore } from '@/store/SettingsStore';
import * as UnitConversion from '@/services/UnitConversionService.js';

export default {
  data () {
    return {
      isHidden: false,
      startY: 0
    };
  },
  computed: {
    recordTotal () {
      return useSprinklerStore().records.length;
    },
    recordCount () {
      let countSelected = 0;
      useSprinklerStore().records.forEach((item) => {
        if (item.isMarked) {
          countSelected = countSelected + 1;
        }
      });
      return countSelected;
    },
    getRecord () {
      return useSprinklerStore().selectedRecord;
    },
    getTotalPrintPages () {
      return usePrintStore().getTotalPrintPages;
    }
  },
  components: {
    RecordsTable,
  },

  methods: {
    handlePrint (e) {
      e.stopImmediatePropagation();
    },
    handlePrintScreen () {
      usePrintStore().setPrintIsOpen(true);
    },
    handleRecordBgClick () {
      usePrintStore().selectLastInPrintQueue();
    },
    handleCSVDownload () {
      const sprinklerStore = useSprinklerStore();
      sprinklerStore.records.forEach((element) => {
        if ( element.isMarked) {
          let record = element;
          const headers = [
            'Rec#',
            'Sprinkler',
            'Model',
            'Plate',
            'Nozzle',
            'Wetted Radius',
            'Trajectory',
            'Pressure',
            'Riser Ht',
            'Layout',
            'Sprinkler Spacing',
            'Offset',
            '%Overlap',
            'CU',
            'DU',
            'SC',
            'Flow Rate',
            'Mean Precip.:',
            'Min Precip:',
            'Max Precip:',
            'Wind Speed'
          ];
          const records = [
            record.RecNum,
            record.Sprinkler,
            record.Model,
            record.Nozzle,
            record.Plate,
            this.filterLength(record.Radius),
            record.Traj,
            this.filterPressure(record.Pressure),
            this.filterHeight(record.RiserHt),
            record.Layout,
            this.getFilterSpacing(record.Spacing),
            this.filterLength(record.Offset),
            record.PcentOlap,
            record.CU,
            record.DU,
            record.SC,
            this.filterFlow(record.FlowRate),
            this.filterPrecip(record.TheoreticalPrecipRate),
            this.filterPrecip(record.MinRate),
            this.filterPrecip(record.MaxRate),
            this.filterSpeed(record.WndSpd)
          ];
          records.forEach((item, index) => {
            if (item === '' || item === null) {
              records[index] = 'N/A';
            }
          });
          const gridDataTitle = ['Grid Data'];
          const blankSpace = [''];
          const numHeaders = headers.map((item, index) => {
            return index;
          });
          const csvData = [
            numHeaders,
            headers,
            records,
            blankSpace,
            gridDataTitle,
            blankSpace,
            ...record.Grid.map(row => [row.map((item) => this.filterPrecip(item))])
          ];
          const name = record.RecNum + '_' + record.Layout;
          sprinklerStore.saveDataAsCSV(csvData, name);
        }
      });
    },
    handleTableHidden () {
      this.isHidden = !this.isHidden;
    },
    handleDividerDown ( e ) {
      this.$data.startY = e.clientY;
      document.body.addEventListener('mousemove', this.handleMouseMove);
      document.body.addEventListener('mouseup', this.handleMouseUp);
    },
    handleMouseMove ( e ) {
      let dgh = document.getElementById('dataGridHolder');
      let dghbr = dgh.getBoundingClientRect();
      let newValue = (dghbr.height+e.movementY);
      useSettingsStore().setWindowSplit(newValue);
    },
    handleMouseUp () {
      document.body.removeEventListener('mousemove', this.handleMouseMove);
      document.body.removeEventListener('mouseup', this.handleMouseUp);
    },
    getFilterSpacing (spacing) {
      const s = spacing.split('x').map(function (item) {
        return parseFloat(item, 10);
      });
      const t = s.map((num) => {
        const convNum = this.filterLength(num, useSettingsStore().lengthUnit);
        return parseFloat(convNum).toFixed(2);
      });
      return t[0] + 'x' + t[1];
    },
    filterFlow (flow) {
      return (UnitConversion.FilterFlow(flow, useSettingsStore().flowUnit)).toFixed(2);
    },
    filterHeight (height) {
      return (UnitConversion.FilterHeight(height, useSettingsStore().heightUnit)).toFixed();
    },
    filterLength (length) {
      return (UnitConversion.FilterLength(length, useSettingsStore().lengthUnit)).toFixed(2);
    },
    filterPressure (pressure) {
      return (UnitConversion.FilterPressure(pressure, useSettingsStore().pressureUnit)).toFixed();
    },
    filterPrecip (precip) {
      return (UnitConversion.FilterPrecip(precip, useSettingsStore().precipUnit)).toFixed(2);
    },
    filterSpeed (speed) {
      return (UnitConversion.FilterSpeed(speed, useSettingsStore().speedUnit)).toFixed(2);
    }
  }
};
</script>

<style scoped>
.table-view-records-table {
  flex: 1;
}
.records-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
  width: 100%;
  border-top: 1px solid gray;
}
.records-divider {
  height: 3px;
  background-color: gray;
  margin-top: 2px;
  cursor: ns-resize;
}
.title-records {
  font-size: 70%;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.records-header {
  display: flex;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  background-color: whitesmoke;
  border-bottom: 1px solid gray;
}
.print-button {
  background-color: #00bd5e;
}
.csv-button {
  background-color: #005ebd;
}
.record-btn-holder {
  display: flex;
}
.record-btn {
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 120px;
  user-select: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .85em;
  font-weight: 600;
  margin-left: 5px;
}
</style>