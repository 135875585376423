<template>
  <div :id="'sh-' + getId" class="data-option-header" @click="toggleExpand">
    <div class="data-option-arrow">
      <img id="sh-inputArrowImage" :src="require('@/assets/svg/SVG/down_arrow.svg')" ref="downArrow"/>
    </div>
    <label id="sh-titleLabel" class="data-option-title">{{ getTitle }}</label>
  </div>
  <div class="data-option-line"></div>
  <div :class="getExpanded ? 'expanded' : 'contracted'" class="slot-holder">
    <slot/>
  </div>
</template>

<script>
import { useSprinklerStore } from '@/store/SprinklerStore';
import { gsap } from 'gsap';

export default {
  name: 'DataInputOption',
  props: [
    'type'
  ],
  watch :{
    getArrow: {
      handler () {
        this.getArrowDirection();
      },
      deep: false
    },
  },
  mounted () {
    this.getArrowDirection();
  },
  computed: {
    getExpanded () {
      return useSprinklerStore().getDataExpanded(this.$props.type);
    },
    getId () {
      const title = useSprinklerStore().getTitle(this.$props.type);
      const removeSpaces = title.replace(/\s/g, '');
      return removeSpaces;
    },
    getTitle () {
      return useSprinklerStore().getTitle(this.$props.type);
    },
    getArrow () {
      return useSprinklerStore().getDataExpanded(this.$props.type);
    }
  },
  methods: {
    toggleExpand () {
      useSprinklerStore().setDataExpanded(this.$props.type, !useSprinklerStore().getDataExpanded(this.$props.type));
    },
    getArrowDirection () {
      let bool = useSprinklerStore().getDataExpanded(this.$props.type);
      gsap.to(this.$refs.downArrow, {
        rotation: (bool) ? 180 : 0,
        duration: .3,
        ease: 'power3.inOut'
      });
    }
  }
};
</script>

<style>
.data-option-header {
  display: flex;
  padding-left: 10px;
}
.data-option-title {
  font-size: 90%;
  font-weight: 600;
  margin: 5px;
}
.centered {
  text-align: center;
}
.slot-holder {
  padding-right: 15px;
}
</style>

<style scoped>
.expanded {
  max-height:1000px;
  height: auto;
  overflow: hidden;
}
.contracted {
  max-height:1000px;
  height: 0;
  overflow: hidden;
  transition: height .3s;
}
.data-option-line {
  width: 100%;
  height: .5px;
  background-color: #d0d0d0;
}
.data-option-arrow {
  margin-top: 2px;
  width: 10px;
  margin-right: 2px;
}
</style>