<template>
  <div class="psd-holder">
    <div class="psd-label">{{ getLabel }}</div>
    <div class="psd-value">{{ getValue }}</div>
    <div class="psd-unit">{{ getUnits }}</div>
  </div>
</template>

<script>
import { useSettingsStore } from '@/store/SettingsStore.js';

export default {
  name: 'PrintSprinklerData',
  data () {
    return {
      printDebug: 0
    };
  },
  props: [
    'data',
    'record'
  ],
  computed: {
    getLabel () {
      return this.$props.data.label;
    },
    getValue () {
      return (this.$props.record !== undefined) ? useSettingsStore().valueFor(this.$props.data.unit, this.$props.record[this.$props.data.value]) : '';
    },
    getUnits () {
      return (this.$props.data.unit) ? useSettingsStore().unitFor(this.$props.data.unit) : '';
    },
    getRandom () {
      this.$data.printDebug++;
      return this.$data.printDebug;
    }
  },
};
</script>

<style scoped>
  .psd-holder {
    display: flex;
    height: 22px;
  }
  .psd-label {
    font-weight: 600;
    font-size: 80%;
    width: 85px;
    padding-right: 8px;
    border-right: .5px solid #adadad;
    border-bottom: .5px solid #adadad;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .psd-value {
    width: 110px;
    font-size: 80%;
    padding-left: 8px;
    border-bottom: .5px solid #adadad;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    text-overflow: clip;
  }
  .psd-unit {
    font-weight: 600;
    font-size: 65%;
    display: flex;
    padding-left: 5px;
    align-items: flex-end;
  }
</style>