<template>
  <div class="table-container">
    <table v-if="records" class="table-records">
      <thead style="min-width: 100%;" class="table-header">
        <tr>
          <th class="table-select-all" @click="this.handleSelectAll">
            <img v-if="getIsAll || getSelectedRecordsCount === records.length && records.length != 0" class="table-check-icon" src="../../assets/svg/SVG/check_on.svg">
            <img v-else class="table-check-icon"  src="../../assets/svg/SVG/check_off.svg">
          </th>
          <th v-for="(head, index) in headers" :key="index" class="table-header-title" v-show="head.isHidden === false">
            <div class="table-header-title-box">
              <div @click="this.handleTableSort(head)">
                {{ head.title }}
                <img v-if="this.isSortAscend === false && this.currSortKey === head.sortKey" class="table-arrow-icon-desc" src="../../assets/svg/SVG/down_arrow.svg">
                <img v-if="this.isSortAscend && this.currSortKey === head.sortKey" class="table-arrow-icon-asc" src="../../assets/svg/SVG/down_arrow.svg">
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th v-for="(subhead, index) in subHeaders" :key="index" class="table-header-subtitle" v-show="subhead.isHidden === false">
            {{ subhead.unit }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body-holder">
        <tr v-for="(record, index) in records" :key="index" :id="'row' + index" class="table-body" @click="selectRecord($event, record)">
          <td class="table-check-box">
            <div :style="this.handleSelectedDefault(record, 'column1')">
              <img v-if="record.isMarked" style="width: 20px;" src="../../assets/svg/SVG/check_on.svg">
              <img v-if="record.isMarked === false" style="width: 20px;" src="../../assets/svg/SVG/check_off.svg">
            </div>
          </td>
          <td v-if="record.isRecNum !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.RecNum }}</div>
          </td>
          <td v-if="record.isSprinkler !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Sprinkler }}</div>
          </td>
          <td v-if="record.isModel !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Model }}</div>
          </td>
          <td v-if="record.isNozzle !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Nozzle }}</div>
          </td>
          <td v-if="record.isPlate !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Plate }}</div>
          </td>
          <td v-if="record.isRadius !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterLength(record.Radius) }}</div>
          </td>
          <td v-if="record.isFlowRate !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterFlow(record.FlowRate) }}</div>
          </td>
          <td v-if="record.isTraj !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Traj }}</div>
          </td>
          <td v-if="record.isPressure !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterPressure(record.Pressure) }}</div>
          </td>
          <td v-if="record.isRiserHt !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterHeight(record.RiserHt) }}</div>
          </td>
          <td v-if="record.isLayout !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.Layout }}</div>
          </td>
          <td v-if="record.isSpacing !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.getFilterSpacing(record.Spacing) }}</div>
          </td>
          <td v-if="record.isOffset !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterLength(record.Offset) }}</div>
          </td>
          <td v-if="record.isCU  !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.cU(record) }}</div>
          </td>
          <td v-if="record.isDU !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.dU(record) }}</div>
          </td>
          <td v-if="record.isSC !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.sC(record) }}</div>
          </td>
          <td v-if="record.isPcentOlap !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ record.PcentOlap }}</div>
          </td>
          <td v-if="record.isTheoreticalPrecipRate !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterPrecip(record.TheoreticalPrecipRate) }}</div>
          </td>
          <td v-if="record.isWndSpd !== false">
            <div :style="this.handleSelectedDefault(record, index)">{{ this.filterSpeed(record.WndSpd) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useSprinklerStore } from '@/store/SprinklerStore';
import * as UnitConversion from '@/services/UnitConversionService.js';
import { useSettingsStore } from '@/store/SettingsStore';
import { usePrintStore } from '@/store/PrintStore';

export default {
  data () {
    return {
      isAll: false,
      isSortAscend: false,
      currSortKey: null,
    };
  },
  watch: {
    records (to, from) {
      if (to.length !== from.length) {
        this.currSortKey = null;
        this.isSortAscend = false;
      }
    },
    subHeaders (to, from) {
      from.forEach((element, index) => {
        if (element.isHidden === true) {
          to[index].isHidden = true;
        }
      });
    }
  },
  updated () {
    const printStore = usePrintStore();
    if (printStore.getPrintQueue.length === 0) {
      printStore.addToPrintQueue(useSprinklerStore().records[0]);
    }
  },
  computed: {
    getIsAll () {
      return this.isAll;
    },
    headers () {
      return useSprinklerStore().tableHeaders;
    },
    subHeaders () {
      return useSprinklerStore().tableSubHeaders;
    },
    records () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.records.length !== 0) {
        sprinklerStore.records.forEach((element) => {
          if (element === sprinklerStore.selectedRecord || element.isMarked) {
            element.isMarked = true;
          } else {
            element.isMarked = false;
          }
        });
        sprinklerStore.selectRecord.isMarked = true;
      }
      return sprinklerStore.records;
    },
    getSelectedRecordsCount () {
      let count = 0;
      useSprinklerStore().records.forEach((element) => {
        if (element.isMarked) {
          count = count + 1;
        }
      });
      return count;
    }
  },
  methods: {
    isSelectedRecNum (record) {
      return (usePrintStore().getPrintQueue.findIndex((r) => (record.RecNum === r.RecNum)) > -1 ) ? 'selected-rec-num' : '';
    },
    isSelectedRow (record) {
      return (usePrintStore().getPrintQueue.findIndex((r) => (record.RecNum === r.RecNum)) > -1) ? 'selected-row' : '';
    },
    filterFlow (flow) {
      return (UnitConversion.FilterFlow(flow, useSettingsStore().flowUnit)).toFixed(2);
    },
    filterHeight (height) {
      return (UnitConversion.FilterHeight(height, useSettingsStore().heightUnit)).toFixed();
    },
    filterLength (length) {
      return (UnitConversion.FilterLength(length, useSettingsStore().lengthUnit)).toFixed(2);
    },
    filterPressure (pressure) {
      return (UnitConversion.FilterPressure(pressure, useSettingsStore().pressureUnit)).toFixed();
    },
    filterPrecip (precip) {
      return (UnitConversion.FilterPrecip(precip, useSettingsStore().precipUnit)).toFixed(2);
    },
    filterSpeed (speed) {
      return (UnitConversion.FilterSpeed(speed, useSettingsStore().speedUnit)).toFixed(2);
    },
    cU (record) {
      if (!record.hasWateredRegion) { return record.CU; }
      return record.WateredRegionStats.WateredRegionStatsCU;
    },
    dU (record) {
      if (!record.hasWateredRegion) { return record.DU; }
      return record.WateredRegionStats.WateredRegionStatsDU;
    },
    sC (record) {
      if (!record.hasWateredRegion) { return record.SC; }
      return record.WateredRegionStats.WateredRegionStatsSC;
    },
    getFilterSpacing (spacing) {
      const s = spacing.split('x').map(function (item) {
        return parseFloat(item, 10);
      });
      const t = s.map((num) => {
        const convNum = this.filterLength(num, useSettingsStore().lengthUnit);
        return parseFloat(convNum).toFixed(2);
      });
      return t[0] + 'x' + t[1];
    },
    handleTableSort (item) {
      if (this.records.length != 0) {
        if (item.sortKey != this.currSortKey) {
          this.isSortAscend = false;
        }
        this.isSortAscend = this.isSortAscend ? false : true;
        this.currSortKey = item.sortKey;
        if (this.isSortAscend) {
          this.records.sort((a, b) => {
            const type = typeof (a[item.sortKey]);
            if (type === 'string') {
              return a[item.sortKey] < b[item.sortKey] ? -1 : 1;
            } else {
              return a[item.sortKey] - b[item.sortKey];
            }
          });
        } else {
          this.records.sort((a, b) => {
            const type = typeof (a[item.sortKey]);
            if (type === 'string') {
              return a[item.sortKey] > b[item.sortKey] ? -1 : 1;
            } else {
              return b[item.sortKey] - a[item.sortKey];
            }
          });
        }
      }
    },
    handleSelectAll () {
      if (this.records != 0) {
        const recordsLength = this.records.length;
        let trueCount = 0;
        this.records.forEach((element) => {
          if (element.isMarked) {
            trueCount = trueCount + 1;
          }
        });
        if (trueCount === recordsLength) {
          this.records.forEach((element, index) => {
            if (index === 0) {
              element.isMarked = true;
              useSprinklerStore().selectedRecord = element;
            } else {
              element.isMarked = false;
            }
          });
          this.isAll = false;
        } else {
          this.records.forEach((element) =>{
            element.isMarked = true;
          });
          this.isAll = true;
        }
      }
    },
    handleSelectedDefault (element, index) {
      if (useSprinklerStore().records.length != 0) {
        let style;
          if (element.isMarked) {
            if (index != 'column1') {
              style = { 'background-color': 'rgba(220, 110, 42, 0.25)' };
            } else {
              style = { 'background-color': 'rgba(220, 110, 42, 1)' };
            }
          }
        return style;
      } else {
        return;
      }
    },
    selectRecord (e, record) {
      const isMulti = e.ctrlKey;
      useSprinklerStore().selectRecord(isMulti, record);
    },
  }
};
</script>

<style scoped>
.table-container {
/*  position: relative;*/
  width: 100%;
  display: block;
/*  max-height: 380px;*/
  overflow-y: auto;
  height: 100%;
}
.table-header {
  top: 0;
  position: sticky;
  background-color: #fafafa;
  border-collapse: separate;
}
tr td div {
  padding: 0px 0;
}
.selected-rec-num {
  background-color: rgba(220, 110, 42, 1) !important;
  color: white;
}
.selected-row {
  background-color: rgba(220, 110, 42, .25) !important;
}
tr td:not(first-child) div {
  background-color: #fafafa;
  height: 30px;
}
tr td:first-child div {
  height: 30px;
  text-align: left;
  border-radius: 15px 0 0 15px;
  padding-left: 15px;
}
tr td:last-child div {
  height: 30px;
  text-align: center;
  border-radius: 0px 15px 15px 0px;
  padding-left: 15px;
}
th:last-child,
td:last-child {
  border-right: 0px solid darkgray;
}
td {
  font-size: small;
  width: 12vw;
  text-align: center;
  border-right: 0px solid darkgray;
}
.table-records {
  table-layout: auto;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
/*  display: flex;
  flex-direction: column;*/
}
.table-header-title {
  cursor: pointer;
  font-size: .8em;
  line-height: 1.3em;
  border: 0.5px solid lightgray;
  border-bottom: 1px solid gray;
  padding: 10px 3px 10px 3px;
}
.table-header-subtitle {
  border: 0.5px solid lightgray;
  text-align: center;
  padding: 5px 5px 5px 5px;
  font-size: .76em;
}
.table-body td {
  padding-top: 3px;
  padding-bottom: 3px;
  border-right: 0.5px solid lightgray;
}
.table-body div {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: .9em;
  background-color: whitesmoke;
  cursor: pointer;
}
.table-btn {
  background-color: #00bd5e;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
.table-select-all {
  cursor: pointer;
  width: 6vw;
}
.table-header-title-box {
  display: flex;
  flex-direction: column;
}
.table-check-icon {
  width: 20px;
}
.table-arrow-icon-desc, .table-arrow-icon-asc {
  width: 10px;
}
.table-arrow-icon-asc {
  rotate: 180deg;
}
.table-body-holder {
  width: 100%;
}

</style>