<template>
  <div class="input-row" v-if="multiSpace">
    <div class="row-element">
    </div>
    <div class="row-element multi">
      <label id="sp-startLabel">Start</label>
      <label id="sp-endLabel">End</label>
      <label id="sp-incrementLabel">Inc</label>
      <label id="sp-placeholderLabel">&nbsp;</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="sp-numberRowsLabel">Number of Rows</label>
      <input id="sp-numRowsInput" class="input-text" v-model="numRows">
    </div>
    <div class="row-element">
      <label id="sp-sprSpacingLabel">Sprinkler Spacing</label>
      <input id="sp-sprSpacingInput" class="input-text" v-model="sprSpacing">
      <input id="sp-sprSpacingEndInput" class="input-text" v-if="multiSpace" v-model="sprSpacingEnd">
      <input id="sp-sprIncrementInput" class="input-text" v-if="multiSpace" v-model="sprIncrement">
      <label id="sp-sprSpacingUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="sp-sprinklersInRowLabel">Sprinklers in Row</label>
      <input id="sp-sprPerRowInput" class="input-text" v-model="sprPerRow">
    </div>
    <div class="row-element">
      <label id="sp-pipeSpacingLabel">Pipe Spacing</label>
      <input id="sp-pipeSpacingInput" class="input-text" :disabled="isEquilateral" v-model="pipeSpacing">
      <input id="sp-pipeSpacingEndInput" class="input-text" :disabled="isEquilateral" v-if="multiSpace" v-model="pipeSpacingEnd">
      <input id="sp-pipeIncrementInput" class="input-text" :disabled="isEquilateral" v-if="multiSpace" v-model="pipeIncrement">
      <label id="sp-pipeSpacingUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
    </div>
    <div class="row-element">
      <input id="sp-multiSpaceInput" class="checkbox" type="checkbox" v-model="multiSpace">
      <label id="sp-multiSpaceLabel" class="input-label">Multiple</label>
    </div>
  </div>
</template>

<script>
import { useSprinklerStore } from '@/store/SprinklerStore';
import { useSettingsStore } from '@/store/SettingsStore';

export default {
  computed: {
    lengthUnit () {
      return useSettingsStore().lengthUnit;
    },
    isEquilateral: {
      get () {
        return useSprinklerStore().isEquilateralLayout;
      }
    },
    numRows: {
      get () {
        return useSprinklerStore().numRows;
      },
      set (value) {
        useSprinklerStore().numRows = value;
      }
    },
    sprSpacing: {
      get () {
        return useSprinklerStore().sprSpacing;
      },
      set (value) {
        useSprinklerStore().sprSpacing = value;
      }
    },
    sprSpacingEnd: {
      get () {
        return useSprinklerStore().sprSpacingEnd;
      },
      set (value) {
        useSprinklerStore().sprSpacingEnd = value;
      }
    },
    sprIncrement: {
      get () {
        return useSprinklerStore().sprIncrement;
      },
      set (value) {
        useSprinklerStore().sprIncrement = value;
      }
    },
    sprPerRow: {
      get () {
        return useSprinklerStore().sprPerRow;
      },
      set (value) {
        useSprinklerStore().sprPerRow = value;
      }
    },
    pipeSpacing: {
      get () {
        return useSprinklerStore().getPipeSpacing;
      },
      set (value) {
        useSprinklerStore().pipeSpacing = value;
      }
    },
    pipeSpacingEnd: {
      get () {
        return useSprinklerStore().getPipeSpacingEnd;
      },
      set (value) {
        useSprinklerStore().pipeSpacingEnd = value;
      }
    },
    pipeIncrement: {
      get () {
        return useSprinklerStore().getPipeIncrement;
      },
      set (value) {
        useSprinklerStore().pipeIncrement = value;
      }
    },
    multiSpace: {
      get () {
        return useSprinklerStore().multiSpace;
      },
      set (value) {
        useSprinklerStore().setMultiSpace(value);
      }
    },
  },
};
</script>

<style>
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  max-width: 50vw;
}
.multi {
  justify-content: flex-end;
}
.multi label{
  width: 42px !important;
  flex: none !important;
  margin:0 !important;
}
</style>

<style scoped>
label {
  margin-left: 10px;
  flex:1;
}
.input-label {
  margin: 4px;
}
.unit-label {
  text-align: center;
  flex: 0;
}
</style>