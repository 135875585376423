<template>
  <div class="checkbox-row">
    <div class="row-element">
      <input id="l-rectangularInput" class="checkbox" type="checkbox" v-model="rectangular">
      <label id="l-rectangularLabel">Rectangular</label>
    </div>
    <div class="row-element">
      <input id="l-triangularInput" class="checkbox" type="checkbox" v-model="triangular">
      <label id="l-triangularLabel">Triangular</label>
    </div>
    <div class="row-element">
      <input id="l-equilateralInput" class="checkbox" type="checkbox" v-model="equilateral">
      <label id="l-equilateralLabel">Equilateral</label>
    </div>
  </div>
  <div class="checkbox-row">
    <div class="row-element">
      <input id="l-offsetRectangularInput" class="checkbox" type="checkbox" v-model="rectangularOffset">
      <label id="l-offsetRectangularLabel">Offset Rectangular</label>
    </div>
    <div class="row-element">
      <input id="l-offsetTriangularInput" class="checkbox" type="checkbox" v-model="triangularOffset">
      <label id="l-offsetTriangularLabel">Offset Triangular</label>
    </div>
    <div class="row-element">
      <label id="l-offsetLabel" class="offset-label">Offset</label>
      <input id="l-offsetInput" class="input-text" :disabled="!rectangularOffset && !triangularOffset" v-model="sprOffset">
      <label id="l-offsetUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
</template>

<script>
import { useSprinklerStore } from '@/store/SprinklerStore';
import { useSettingsStore } from '@/store/SettingsStore';

export default {
  computed: {
    lengthUnit () {
      return useSettingsStore().lengthUnit;
    },
    rectangular: {
      get () {
        return useSprinklerStore().hasLayout('Rectangular');
      },
      set (value) {
        useSprinklerStore().addRemoveLayout('Rectangular', value);
      }
    },
    triangular: {
      get () {
        return useSprinklerStore().hasLayout('Triangular');
      },
      set (value) {
        useSprinklerStore().addRemoveLayout('Triangular', value);
      }
    },
    equilateral: {
      get () {
        return useSprinklerStore().hasLayout('Equilateral');
      },
      set (value) {
        useSprinklerStore().addRemoveLayout('Equilateral', value);
      }
    },
    rectangularOffset: {
      get () {
        return useSprinklerStore().hasLayout('Offset Rectangular');
      },
      set (value) {
        useSprinklerStore().addRemoveLayout('Offset Rectangular', value);
      }
    },
    triangularOffset: {
      get () {
        return useSprinklerStore().hasLayout('Offset Triangular');
      },
      set (value) {
        useSprinklerStore().addRemoveLayout('Offset Triangular', value);
      }
    },
    sprOffset: {
      get () {
        return useSprinklerStore().sprOffset;
      },
      set (value) {
        useSprinklerStore().sprOffset = value;
      }
    },
  },
};
</script>

<style>
label {
  font-size: 80%;
  margin-left:4px;
  line-height: 1.1em;
}
.checkbox-row {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-left: 10px;
  justify-content: flex-start;
  align-items: center;
  max-width: 50vw;
}
.checkbox {
  margin-left: 10px;
}
.input-text {
  border: 0 solid black;
  border-radius: 10px;
  height: 20px;
  width: 3.5vh;
  max-width: 50px;
  margin-left: 4px;
  text-align: center;
  font-weight: 600;
  background-color: whitesmoke;
}
.row-element {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.unit-label {
  font-size: 80%;
  text-align: center;
}
</style>

<style scoped>
.offset-label {
  margin-left: 15px;
  font-size: 80%;
}
</style>