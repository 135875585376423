import { defineStore } from 'pinia';
import ApiService from '@/services/ApiService';
import { useSettingsStore } from './SettingsStore';
import * as UnitConversion from '@/services/UnitConversionService.js';

export const useSprinklerStore = defineStore('sprinkler', {
  state () {
    return {
      menu: null,
      sprinklers: null,
      // DATA INPUT
      dataExpanded: {
        sprinkler: true,
        layout: true,
        spacing: true,
        treeVineSpacing: false,
        filters: false,
        wateredRegion: false,
      },
      panels: {
        dataInput: null,
        sprinklerGrid: null,
      },
      dataInputExpanded: false,
      sprinklerLayoutOptions: [
        { name: 'Rectangular', value: 0 },
        { name: 'Triangular', value: 1 },
        { name: 'Equilateral', value: 2 },
        { name: 'Offset Rectangular', value: 3 },
        { name: 'Offset Triangular', value: 4 },
      ],
      treeLayoutOptions: ['Rectangular', 'Triangular', 'Equilateral'],
      selectedSprinklers: [],
      selectedModels: [],
      selectedTrajectories: [],
      selectedNozzles: [],
      selectedPressures: [],
      selectedRisers: [],
      selectedOrientations: [],
      applicationRateMax: 0,
      applicationRateMin: 0,
      distFromEnd: 0,
      distFromSide: 0,
      firstSprDistance: 0,
      flowRateMax: 0,
      flowRateMin: 0,
      irrStripWidth: 0,
      layouts: [0],
      multiSpace: false,
      numRows: 4,
      numberOfTreeRows: 0,
      numberOfTreesInRow: 0,
      pipeDirection: '90',
      pipeIncrement: 0,
      pipeOrigin: 0,
      pipeSpacing: 20,
      pipeSpacingEnd: 0,
      sprIncrement: 0,
      sprOffset: 0,
      sprPerRow: 6,
      sprSpacing: 20,
      sprSpacingEnd: 0,
      treeDiameter: 0,
      treeLayout: 'Rectangular',
      treeRowSpacing: 0,
      treeSpacing: 0,
      wettedWidth: 0,
      wettedLength: 0,
      // winds: ['false'],
      winds: [false, true], // [0] is no wind, [1] is wind, [2] is both
      windDirection: '0',

      // RECORDS
      records: [],
      selectedRecord: null,
      selectedRecords: [],
    };
  },
  getters: {
    hasIncompleteInputs () {
      if (this.selectedOrientations.length == 0 || this.layouts.length == 0) {
        return true;
      }
      if (!this.isValidNumber(this.numRows) || !this.isValidNumber(this.sprPerRow) || !this.isValidNumber(this.sprSpacing) || !this.isValidNumber(this.sprSpacingEnd) || !this.isValidNumber(this.sprIncrement) || !this.isValidNumber(this.getPipeSpacing) || !this.isValidNumber(this.getPipeSpacingEnd) || !this.isValidNumber(this.getPipeIncrement)) {
        return true;
      }
      if (!this.isValidNumber(this.pipeOrigin) || !this.isValidNumber(this.treeSpacing) || !this.isValidNumber(this.firstSprDistance) || !this.isValidNumber(this.treeRowSpacing) || !this.isValidNumber(this.irrStripWidth) || !this.isValidNumber(this.treeDiameter)) {
        return true;
      }
      if (!this.isValidNumber(this.applicationRateMin) || !this.isValidNumber(this.applicationRateMax) || !this.isValidNumber(this.flowRateMax) || !this.isValidNumber(this.flowRateMin)) {
        return true;
      }
      if (!this.isValidNumber(this.distFromEnd) || !this.isValidNumber(this.distFromSide) || !this.isValidNumber(this.wettedWidth) || !this.isValidNumber(this.wettedLength)) {
        return true;
      }
      return false;
    },
    isTriangularLayout () {
      return this.layouts.includes(1);
    },
    isEquilateralLayout () {
      return this.layouts.includes(2);
    },
    isEquilateralTreeLayout () {
      return this.treeLayout === 'Equilateral';
    },
    getSprOffset () {
      if (this.isTriangularLayout || this.isEquilateralLayout) {
        return parseFloat(this.sprSpacing) * 0.5;
      }
      return this.sprOffset;
    },
    getPipeSpacing () {
      if (this.isEquilateralLayout) {
        if (this.sprSpacing == '') {
          return this.sprSpacing;
        }
        return parseFloat(this.sprSpacing) * 0.86602540378;
      }
      else if (this.pipeSpacing == '') {
        return this.pipeSpacing;
      }
      return parseFloat(this.pipeSpacing);
    },
    getPipeSpacingEnd () {
      if (this.isEquilateralLayout) {
        if (this.sprSpacingEnd == '') {
          return this.sprSpacingEnd;
        }
        return parseFloat(this.sprSpacingEnd) * 0.86602540378;
      }
      else if (this.pipeSpacingEnd == '') {
        return this.pipeSpacingEnd;
      }
      return parseFloat(this.pipeSpacingEnd);
    },
    getPipeIncrement () {
      if (this.isEquilateralLayout) {
        if (this.sprIncrement == '') {
          return this.sprIncrement;
        }
        return parseFloat(this.sprIncrement) * 0.86602540378;
      }
      else if (this.pipeIncrement == '') {
        return this.pipeIncrement;
      }
      return parseFloat(this.pipeIncrement);
    },
    getTreeRowSpacing () {
      if (this.isEquilateralTreeLayout) {
        if (this.treeSpacing == '') {
          return this.treeSpacing;
        }
        return parseFloat(this.treeSpacing) * 0.86602540378;
      }
      else if (this.treeRowSpacing == '') {
        return this.treeRowSpacing;
      }
      return parseFloat(this.treeRowSpacing);
    },
    tableSubHeaders () {
      const settingsStore = useSettingsStore();
      return [
        { unit: null, isHidden: false },
        { unit: null, isHidden: false },
        { unit: null, isHidden: false },
        { unit: null, isHidden: false },
        { unit: null, isHidden: false },
        { unit: null, isHidden: false },
        { unit: settingsStore.lengthUnit, isHidden: false },
        { unit: settingsStore.flowUnit, isHidden: false },
        { unit: 'degrees', isHidden: false },
        { unit: settingsStore.pressureUnit, isHidden: false },
        { unit: settingsStore.heightUnit, isHidden: false },
        { unit: null, isHidden: false },
        { unit: settingsStore.lengthUnit, isHidden: false },
        { unit: settingsStore.lengthUnit, isHidden: false },
        { unit: '%', isHidden: false },
        { unit: '%', isHidden: false },
        { unit: '%', isHidden: false },
        { unit: null, isHidden: false },
        { unit: settingsStore.precipUnit, isHidden: false },
        { unit: settingsStore.speedUnit, isHidden: false }
      ];
    },
    tableHeaders () {
      return [
        { title: 'Record #', align: 'end', key: 'record_num', sortKey: 'RecNum', isHidden: false },
        { title: 'Sprinkler', align: 'end', key: 'sprinkler', sortKey: 'Sprinkler', isHidden: false },
        { title: 'Model', align: 'end', key: 'model', sortKey: 'Model', isHidden: false },
        { title: 'Nozzle', align: 'end', key: 'nozzle', sortKey: 'Nozzle', isHidden: false },
        { title: 'Plate', align: 'end', key: 'plate', sortKey: 'Plate', isHidden: false },
        { title: 'Radius', align: 'end', key: 'radius', sortKey: 'Radius', isHidden: false },
        { title: 'FlowRate', align: 'end', key: 'flow_rate', sortKey: 'FlowRate', isHidden: false },
        { title: 'Trajectory', align: 'end', key: 'trajectory', sortKey: 'Traj', isHidden: false },
        { title: 'Pressure', align: 'end', key: 'pressure', sortKey: 'Pressure', isHidden: false },
        { title: 'Riser Height', align: 'end', key: 'riser_height', sortKey: 'RiserHt', isHidden: false },
        { title: 'Layout', align: 'end', key: 'layout', sortKey: 'Layout', isHidden: false },
        { title: 'Spacing', align: 'end', key: 'spacing', sortKey: 'Spacing', isHidden: false },
        { title: 'Offset', align: 'end', key: 'offset', sortKey: 'Offset', isHidden: false },
        { title: 'CU', align: 'end', key: 'cu', sortKey: 'CU', isHidden: false },
        { title: 'DU', align: 'end', key: 'du', sortKey: 'DU', isHidden: false },
        { title: 'SC', align: 'end', key: 'sc', sortKey: 'SC', isHidden: false },
        { title: '% Overlap', align: 'end', key: 'percent_overlap', sortKey: 'PcentOlap', isHidden: false },
        { title: 'Theoretical AppRate', align: 'end', key: 'theory_apprate', sortKey: 'TheoreticalPrecipRate', isHidden: false },
        { title: 'WndSpd', align: 'end', key: 'wind_speed', sortKey: 'WndSpd', isHidden: false },
      ];
    },
    maxGridValue () {
      if (this.selectedRecord == null) {
        return 0;
      }
      return this.selectedRecord.MaxRate;
    },
    minGridValue () {
      if (this.selectedRecord == null) {
        return 0;
      }
      return this.selectedRecord.MinRate;
    },
    getDataInputExpanded () {
      return this.dataInputExpanded;
    },
    getDataInputPanel () {
      return this.panels.dataInput;
    },
  },
  actions: {
    isValidNumber (value) {
      const parsedValue = parseFloat(value);
      return parsedValue || parsedValue == 0;
    },
    setMultiSpace (value) {
      this.multiSpace = value;
      if (value == false) {
        this.sprSpacingEnd = 0;
        this.sprIncrement = 0;
        this.pipeSpacingEnd = 0;
        this.pipeIncrement = 0;
      }
    },
    sprinklerXOffsetFor (rowIndex) {
      const record = this.selectedRecord;
      if (record == null) { return 0; }
      var offset = 0.5;
      const layout = record.Layout;
      if (rowIndex % 2 != 0 && (layout == 'Triangular' || layout == 'Equilateral')) {
        offset += record.SprinklerSpacing * 0.5;
      } else if (layout == 'OffsetRectangular') {
        offset += record.Offset * rowIndex;
      } else if (layout == 'OffsetTriangular' && rowIndex % 2 != 0) {
        offset = record.Offset;
      }
      return offset;
    },
    treeXOffsetFor (rowIndex) {
      const record = this.selectedRecord;
      if (record == null) { return 0; }
      const treeOffset = this.filterLength(this.firstSprDistance);
      const xOffset = 0.5;
      var offset = treeOffset + xOffset;
      const layout = record.TreeLayout;
      if ((rowIndex % 2 != 0) && (layout == 'Triangular' || layout == 'Equilateral')) {
        offset += this.filterLength(this.treeSpacing) * 0.5;
      }
      return offset;
    },
    getDataExpanded (type) {
      return this.dataExpanded[type];
    },
    setDataExpanded (type, bool) {
      this.dataExpanded[type] = bool;
    },
    expandContractAllDataInputs (bool) {
      Object.keys(this.dataExpanded).forEach((key) => {
        this.dataExpanded[key] = bool;
      });
    },
    expandAllDataInputs () {
      this.expandContractAllDataInputs(true);
    },
    contractAllDataInputs () {
      this.expandContractAllDataInputs(false);
    },
    setDataInputPanel (ref) {
      this.panels.dataInput = ref;
    },
    setSprinklerGridPanel (ref) {
      this.panels.sprinklerGrid = ref;
    },
    shrinkSprinklerGridPanel () {
      this.panels.sprinklerGrid.shrink();
      this.dataInputExpanded = true;
    },
    expandSprinklerGridPanel () {
      this.panels.sprinklerGrid.expand();
      this.dataInputExpanded = false;
    },
    selectRecord (isMulti, record) {
      if (this.selectedRecord != null) {
        if (isMulti) {
          const index = this.records.indexOf(record);
          let markedRecordCount = 0;
          let selectedRecordsIndex = [];
          this.records.forEach((element, num) => {
            if (element.isMarked) {
              markedRecordCount = markedRecordCount + 1;
              if (num != index) {
                selectedRecordsIndex.push(num);
              }
            }
          });
          if (this.records[index].isMarked) {
            this.records[index].isMarked = false;
            record.isMarked = false;
            if (markedRecordCount === 1) {
              this.records[0].isMarked = true;
              this.selectedRecord = this.records[0];
            } else {
              const closest = (array, goal) => array.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));
              const currRecordIndex = closest(selectedRecordsIndex, index);
              this.records[currRecordIndex].isMarked = true;
              this.selectedRecord = this.records[currRecordIndex];
            }
          } else {
            this.records[index].isMarked = true;
            record.isMarked = true;
            this.selectedRecord = record;
          }
        } else {
          const index = this.records.indexOf(record);
          this.records.forEach((element) => {
            element.isMarked = false;
          });
          this.records[index].isMarked = true;
          record.isMarked = true;
          this.selectedRecord = record;
        }
      } else {
        this.selectedRecord = this.records[0];
      }
    },
    calculateTreeRows () {
      if (this.getTreeRowSpacing == 0 || this.treeSpacing == 0) { return; }
      // todo: take into consideration Pipe Origin and 1st spr distance from 1st tree/vine
      var sprFieldWidth = (parseFloat(this.sprPerRow) - 1) * parseFloat(this.filterLength(this.sprSpacing));
      var sprFieldHeight = (parseFloat(this.numRows) - 1) * parseFloat(this.filterLength(this.getPipeSpacing));
      var numTreesAcross = Math.floor(sprFieldWidth / parseFloat(this.filterLength(this.treeSpacing)));
      var numTreeRows = Math.floor(sprFieldHeight / parseFloat(this.filterLength(this.getTreeRowSpacing)));
      this.numberOfTreeRows = Math.round(numTreeRows) + 1;
      this.numberOfTreesInRow = Math.round(numTreesAcross) + 1;
    },
    hasLayout (layout) {
      const layoutValue = this.sprinklerLayoutOptions.find((each) => each.name == layout).value;
      return this.layouts.includes(layoutValue);
    },
    addRemoveLayout (layout, shouldAdd) {
      const layoutValue = this.sprinklerLayoutOptions.find((each) => each.name == layout).value;
      if (shouldAdd) {
        if (layoutValue == 2) {
          this.layouts = [];
        }
        else {
          this.layouts = this.layouts.filter((each) => each != 2);
        }
        this.layouts.push(layoutValue);
      } else {
        this.layouts = this.layouts.filter((each) => each != layoutValue);
      }
    },

    // utils
    getTitle (str) {
      let result = str.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    getWinds () {
      let selection = 0;
      if (!this.winds[1] && this.winds[0]) {// wind is winds[0] and no wind is winds[1]
        selection = 1;
      }
      if (this.winds[0] && this.winds[1]) {
        selection = 2;
      }
      return selection;
    },
    async getMenu () {
      return await ApiService.getSprinklerTypes()
        .then((response) => {
          this.menu = response.data;
          // console.log(this.menu);
          this.resetSprinklerSelection();
        })
        .catch((error) => {
          throw error;
        });
    },
    filterLength (value) {
      if (useSettingsStore().lengthUnit === 'm') {
        return UnitConversion.ConvertMetersToFt(value);
      }
      return parseFloat(value);
    },
    filterLengthLayout (value) {
      return parseFloat(value);
    },
    filterPrecip (value) {
      if (useSettingsStore().precipUnit === 'mm/hr') {
        return UnitConversion.ConvertMMHtoINH(value);
      }
      return parseFloat(value);
    },
    filterFlow (value) {
      const settingsStore = useSettingsStore();
      if (settingsStore.flowUnit === 'gal/hr') {
        return UnitConversion.ConvertGPHtoGPM(value);
      } else if (settingsStore.flowUnit === 'liters/sec') {
        return UnitConversion.ConvertLPStoGPM(value);
      } else if (settingsStore.flowUnit === 'liters/min') {
        return UnitConversion.ConvertLPMtoGPM(value);
      } else if (settingsStore.flowUnit === 'liters/hr') {
        return UnitConversion.ConvertLPHtoGPM(value);
      } else if (settingsStore.flowUnit === 'm3/h') {
        return UnitConversion.ConvertM3HtoGPM(value);
      }
      return parseFloat(value);
    },
    async analyze () {
      this.calculateTreeRows();
      this.currentInstance.appContext.config.globalProperties.$Progress.start();
      this.resetHeaders();
      const selection = {
        analysisInputs: {
          applicationRateMax: this.filterPrecip(this.applicationRateMax),
          applicationRateMin: this.filterPrecip(this.applicationRateMin),
          distFromEnd: this.filterLength(this.distFromEnd),
          distFromSide: this.filterLength(this.distFromSide),
          firstSprDistance: this.filterLength(this.firstSprDistance),
          flowRateMax: this.filterFlow(this.flowRateMax),
          flowRateMin: this.filterFlow(this.flowRateMin),
          irrStripWidth: this.filterLength(this.irrStripWidth),
          layouts: this.layouts,
          multiSpace: this.multiSpace,
          numberOfTreeRows: parseFloat(this.numberOfTreeRows),
          numberOfTreesInRow: parseFloat(this.numberOfTreesInRow),
          numRows: parseFloat(this.numRows),
          pipeDirection: this.pipeDirection,
          pipeIncrement: this.filterLength(this.getPipeIncrement),
          pipeOrigin: this.filterLength(this.pipeOrigin),
          pipeSpacing: this.filterLength(this.getPipeSpacing),
          pipeSpacingEnd: this.filterLength(this.getPipeSpacingEnd),
          sprIncrement: this.filterLength(this.sprIncrement),
          sprOffset: this.filterLength(this.getSprOffset),
          sprPerRow: parseFloat(this.sprPerRow),
          sprSpacing: this.filterLength(this.sprSpacing),
          sprSpacingEnd: this.filterLength(this.sprSpacingEnd),
          treeDiameter: this.filterLength(this.treeDiameter),
          treeLayout: this.treeLayout,
          treeRowSpacing: this.filterLength(this.getTreeRowSpacing),
          treeSpacing: this.filterLength(this.treeSpacing),
          wettedWidth: this.filterLength(this.wettedWidth),
          wettedLength: this.filterLength(this.wettedLength),
          wind: this.getWinds(),
          windDirection: this.windDirection,
        },
        sprinklers: this.selectedSprinklers.map((each) => each.SprinklerType),
        models: this.selectedModels.map((each) => each.Model),
        trajectories: this.selectedTrajectories.map((each) => each.Trajectory),
        nozzles: this.selectedNozzles.map((each) => each.Nozzle),
        pressures: this.selectedPressures.map((each) => each.Pressure),
        risers: this.selectedRisers.map((each) => each.Riser),
        orientations: this.selectedOrientations.map((each) => each == 'True' ? true : false),
      };
      return await ApiService.analyze(selection)
        .then((response) => {
          response.data.forEach((element, num) => {
            element.index = num;
            element.CU = Math.round(element.CU).toFixed(0);
            element.DU = Math.round(element.DU).toFixed(0);
            element.SC = Math.round(element.SC * 100) / 100;
          });
          this.records = response.data;
          if (this.records.length != 0) {
            this.selectRecord(false, this.records[0]);
            // console.log('response');
            // console.log(response.data);
            // console.log('GRID (sprSpacing=' + this.filterLength(this.sprSpacing) + ')');
            // const Grid = this.selectedRecord.Grid;
            // var spacing = 20;
            // console.log(Grid[0][0 * spacing] + ' ' + Grid[0][(0 * spacing) + 1] + ' ' + Grid[0][1 * spacing] + ' ' + Grid[0][(1 * spacing) + 1] + ' ' + Grid[0][2 * spacing] + ' ' + Grid[0][(2 * spacing) + 1] + ' ' + Grid[0][3 * spacing] + ' ' + Grid[0][(3 * spacing) + 1]);
            // spacing = 21;
            // console.log(Grid[0][0 * spacing] + ' ' + Grid[0][(0 * spacing) + 1] + ' ' + Grid[0][1 * spacing] + ' ' + Grid[0][(1 * spacing) + 1] + ' ' + Grid[0][2 * spacing] + ' ' + Grid[0][(2 * spacing) + 1] + ' ' + Grid[0][3 * spacing] + ' ' + Grid[0][(3 * spacing) + 1]);
          }
          this.currentInstance.appContext.config.globalProperties.$Progress.finish();
        })
        .catch((error) => {
          throw error;
        });
    },
    saveDataAsCSV (data, name) {
      var csvdata = data.map((e) => e.join(',')).join('\n');
      var type = 'text/csv; charset=utf-8;';
      var blob = new Blob([csvdata], { type: type });
      var filename = name + '.csv';
      if (typeof window.navigator.msSaveBlob === 'undefined') {
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);
        var saving = document.createElement('a');
        saving.href = downloadUrl;
        saving.download = filename;
        document.body.appendChild(saving);
        saving.click();
        document.body.removeChild(saving);
      }
    },
    resetHeaders () {
      this.tableHeaders.forEach((element) => {
        element.isHidden = false;
      });
      this.tableSubHeaders.forEach((element) => {
        element.isHidden = false;
      });
    },
    resetSprinklerSelection () {
      this.sprinklers = this.getWinds() === 0 ? this.menu.NoWind : this.getWinds() === 1 ? this.menu.Wind : this.menu.Combined;
      this.selectedSprinklers = [];
      this.selectedModels = [];
      this.selectedTrajectories = [];
      this.selectedNozzles = [];
      this.selectedPressures = [];
      this.selectedRisers = [];
      this.selectedOrientations = [];
    }
  },
});
