<template>
  <div class="precip-legend-layout">
    <div class="data-option-title">Precipitation ({{ precipUnit }})</div>
    <div class="data-option-divider precip-space"></div>
    <div class="precip-marker-holder" v-for="(value, index) in legendColors" :key="index" >
      <div class="precip-marker-dot" :style="'background-color:' + value + ';'">
      </div>
      <div class="precip-title-text">{{ legendValues[index].toFixed(2) }}</div>
    </div>
  </div>
</template>

<script>
import { useGridStore } from '@/store/GridStore';
import { useSprinklerStore } from '@/store/SprinklerStore';
import { useSettingsStore } from '@/store/SettingsStore';
import * as UnitConversion from '@/services/UnitConversionService.js';

export default {
  computed: {
    precipUnit () {
      return useSettingsStore().precipUnit;
    },
    legendColors () {
      const record = useSprinklerStore().selectedRecord;
      if (record == null) { return []; }
      const maxValue = record.MaxRate;
      var minValue = record.MinRate;
      var increment = (maxValue - minValue) / (this.numberOfLegendValues - 1);
      const values = [];
      for (var i = 0; i < this.numberOfLegendValues; i++) {
        values.push(useGridStore().hexFromValue(parseFloat(minValue) + (i * parseFloat(increment)), maxValue, record.MinRate));
      }
      return values;
    },
    legendValues () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.selectedRecord == null) { return []; }
      var minValue = sprinklerStore.minGridValue;
      var increment = (sprinklerStore.maxGridValue - minValue) / (this.numberOfLegendValues - 1);
      const gridStore = useGridStore();
      if (gridStore.selectedScaleModeIndex == 1) {
        if (gridStore.fixedModeIncrement < 0.00001) { return []; }
        minValue = (minValue.toString().slice(-1) == '.') ? minValue.toString().slice(0, -1) : gridStore.fixedModeMin;
        increment = (increment.toString().slice(-1) == '.') ? increment.toString().slice(0, -1) : gridStore.fixedModeIncrement;
      }
      const values = [];
      for (var i = 0; i < this.numberOfLegendValues; i++) {
        var value = Math.round((parseFloat(minValue) + (i * increment)) * 100) / 100;
        values.push(gridStore.selectedScaleModeIndex == 1 ? value : this.filterPrecip(value));
      }
      return values;
    },
    numberOfLegendValues () {
      return useGridStore().numberOfLegendValues;
    }
  },
  methods: {
    filterPrecip (precip) {
      return parseFloat(UnitConversion.FilterPrecip(precip, useSettingsStore().precipUnit));
    },
    hexFromValue (value, max, min) {
      return useGridStore().hexFromValue(value, max, min);
    }
  }
};
</script>

<style scoped>
.precip-legend-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 140px;
}
.header {
  font-size: 1.6em;
  font-weight: 600;
  margin: 5px;
}
.precip-legend-text {
  font-size: 80%;
}
.precip-marker-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.precip-marker-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
.precip-title-text {
  font-size: 80%;
}
.precip-space {
  margin-bottom: 6px;
}
</style>