<template>
  <div class="print-sheet" :style="getFixedWidth" ref="printSheet">
    <div class="print-sheet-header">
      <div class="psh-user-holder">
        <div class="psh-user">{{ this.initials }}</div>
      </div>
      <div class="psh-logo-holder" :class="getPLHOrientation">
        <div class="psh-logo">
          <img class="psh-logo-img" :src="require('@/assets/svg/nelson_logo.svg')"/>
        </div>
        <div class="psh-title-holder">
          <div class="psh-title">OverlapPro {{ this.$version }}</div>
          <div class="psh-title-data">Sprinkler Precipitation Datasheet</div>
        </div>
      </div>
    </div>
    <div class="print-sheet-holder">
      <div class="print-sheet-left">
        <PrintDataBlock :record="this.$props.record"/>
        <div class="component-spacer"></div>
        <div class="print-sprinkler-holder" :class="getPNOrientation">
          <PrintSprinkler :record="this.$props.record"/>
        </div>
        <div class="component-spacer"></div>
        <div class="print-notes-holder">
          <PrintNotes/>
        </div>
      </div>
      <div class="print-sheet-right" :class="getPSROrientation" id="printSheetRight">
        <div class="wind-info-holder" v-show="hasWind">
          <div id="windArrow" class="wind-direction wind-label">Wind Direction: </div>
          <img id="print-arrow" ref="print-arrow" :src="require('@/assets/png/right-arrow.png')" class="print-arrow" />
          <div class="wind-label">Wind Speed: </div>
          <div class="wind-speed">{{ windSpeed }} {{ speedUnit }}</div>
        </div>
        <div class="psr-grid-holder">
          <OverlapGrid :record="this.$props.record"/>
          <PrecipitationLegend :record="this.$props.record"></PrecipitationLegend>
        </div>
        <PrintTreeVineSpacing :record="this.$props.record" v-if="getTVSpacingState"></PrintTreeVineSpacing>
      </div>
    </div>
    <div class="print-sheet-test-data-holder">
      <PrintTestData></PrintTestData>
    </div>
  </div>
</template>

<script>
import PrintSprinkler from '@/components/print/sheet_components/PrintSprinkler';
import PrintNotes from '@/components/print/sheet_components/PrintNotes';
import PrintTestData from '@/components/print/sheet_components/PrintTestData';
import PrintDataBlock from '@/components/print/sheet_components/PrintDataBlock';
import { usePrintStore } from '@/store/PrintStore';
import { useIdentityStore } from '@/store/IdentityStore';
import PrecipitationLegend from '@/components/sprinkler_grid_components/PrecipitationLegend';
import PrintTreeVineSpacing from '@/components/print/sheet_components/PrintTreeVineSpacing';
import OverlapGrid from '@/components/sprinkler_grid_components/OverlapGrid';
import { useSprinklerStore } from '@/store/SprinklerStore';
import { storeToRefs } from 'pinia/dist/pinia';
import { nextTick } from 'vue';
import { useSettingsStore } from '@/store/SettingsStore';

export default {
  name: 'PrintSheet',
  components: {
    PrecipitationLegend,
    PrintDataBlock,
    PrintTestData,
    PrintSprinkler,
    PrintNotes,
    PrintTreeVineSpacing,
    OverlapGrid
  },
  props: [
    'record'      
  ],
  setup () {
    const { orientation, size } = storeToRefs(usePrintStore());
    return {
      orientation, size
    };
  },
  watch: {
    orientation () {
      nextTick(() => {
        this.handleResize();
      });
    },
    size () {
      nextTick(() => {
        this.handleResize();
      });
    },
  },
  mounted () {
    const sprinklerStore = useSprinklerStore();
    this.handleResize();
    if (this.hasWind) {
      var arrow = document.getElementById('print-arrow');
      arrow.style.transform = 'rotate(' + (sprinklerStore.pipeDirection - sprinklerStore.windDirection + 180) + 'deg)';
    }
    window.addEventListener('resize', this.handleResize);
  },
  computed: {
    initials () {
      const user = useIdentityStore().User;
      return user ? user.initials : '';
    },
    hasWind () {
      return useSprinklerStore().winds[0];
    },
    getPNOrientation () {
      return (usePrintStore().getOrientation === 'portrait') ? 'print-sprinkler-holder-portrait' : '';
    },
    getPSROrientation () {
      return (usePrintStore().getOrientation === 'portrait') ? 'print-sheet-right-portrait' : '';
    },
    getPLHOrientation () {
      return (usePrintStore().getOrientation === 'portrait') ? 'psh-logo-holder-portrait' : '';
    },
    getCanvasGrid () {
      let cg = document.getElementById('canvasGrid');
      return cg;
    },
    getFixedWidth () {
      let px = usePrintStore().getDimensions;
      return 'width:' + px.width  + 'px;min-width:'+ (px.width-4)+'px;height:' + px.height +
        'px;min-height:'+ px.height + 'px;transform-origin:top left; // ;transform: scale(1)';
    },
    speedUnit () {
      return useSettingsStore().speedUnit;
    },
    windSpeed () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.selectedRecord == null) { return 0; }
      return sprinklerStore.selectedRecord.WndSpd;
    },
    getTVSpacingState () {
      return (this.$props.record.TreeSpacing !== '0x0');
    }
  },
  methods: {
    handleResize () {
      let printDisplay = document.getElementById('printDisplay');
      let printSheet = this.$refs.printSheet;
      let widthDifference = printDisplay.clientWidth / (printSheet.clientWidth + 4);
      let heightDifference = printDisplay.clientHeight / (printSheet.clientHeight + 4);
      let scaledPrintSheetW = (printSheet.clientWidth + 4) * heightDifference;
      let scaledPrintSheetH = (printSheet.clientHeight + 4) * widthDifference;
      let difX = printDisplay.clientWidth - scaledPrintSheetW;
      let difY = printDisplay.clientHeight - scaledPrintSheetH;
      if (widthDifference > 1) {
        widthDifference = 1;
      }
      if (difY < 0) {
        difY = 0;
      }
      if (usePrintStore().getOrientation === 'landscape') {
        if (scaledPrintSheetH > printDisplay.clientHeight) {
          printSheet.style.transform = 'scale(' + heightDifference + ')'; // translateY(' + difY + 'px)
        } else {
          printSheet.style.transform = 'scale(' + widthDifference + ')'; //  translateY(' + difY + 'px)
        }
      } else {
        printSheet.style.transform = 'scale(' + heightDifference + ') translateX(' + difX + 'px)'; // translateX(' + difY/2 + 'px);';
      }
    }
  }
};
</script>

<style>
.page-break {
  page-break-after: always;
  height: 0;
  display: block;
}
@media print {
  .print-sheet {
    border: 2px solid transparent !important;
    transform: scale(1) !important;
  }
}
</style>

<style scoped>
.print-sheet-header {
  width: 100%;
  height: 10%;
  display: flex;
}
.print-sheet-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
}
.print-sheet-right {
  flex:1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.print-sheet-right-portrait {
  flex: 1.5;
}
.print-sheet-holder {
  height: 80%;
  display: flex;
}
.print-sheet-test-data-holder {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.component-spacer {
  flex: .5;
}
.print-sprinkler-holder {
  flex: 4;
  max-height: 50%;
  display: flex;
  flex-direction: column;
}
.print-sprinkler-holder-portrait {
  max-height: none;
}
.print-notes-holder {
  flex: 4;
  max-height: 50%;
  display: flex;
  flex-direction: column;
}
.psh-title {
  font-weight: 600;
}
.psh-logo-holder {
  flex:1;
  display: flex;
  align-items: flex-end;
}
.psh-logo-holder-portrait {
  flex: 1.5;
}
.psh-user-holder {
  flex:1;
  padding-left: 20px;
  padding-top: 10px;
}
.psh-logo-img {
  width: 42px;
}
.psh-title-data {
  margin-top: -3px;
}
.psh-title-holder {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.psr-grid-holder {
  overflow: hidden;
  width: 100%;
  display: flex;
}
.print-sheet {
  border: 2px solid black;
  display: block;
  transform: scale(.9);
}
.wind-info-holder {
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  font-weight: 600;
  font-size: 90%;
  align-items: center;
}
.print-arrow {
  height:15px;
  width:auto;
  margin-left: 10px;
  margin-right: 50px;
}
.wind-speed {
  margin-left: 5px;
  font-weight: 400 !important;
}
</style>