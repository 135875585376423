<template>
  <div class="input-row">
    <div class="row-element">
      <label id="wr-distanceFromEndLabel">Distance from end</label>
      <input id="wr-distanceFromEndInput" class="input-text" v-model="distFromEnd">
      <label id="wr-distanceFromEndUnitLabel" class="input-unit">{{ this.lengthUnit }}</label>
    </div>
    <div class="row-element">
      <label id="wr-widthLabel">Width</label>
      <input id="wr-widthInput" class="input-text" v-model="width">
      <label id="wr-widthUnitLabel" class="input-unit">{{ this.lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="wr-distanceFromSideLabel">Distance from side</label>
      <input id="wr-distanceFromSideInput" class="input-text" v-model="distFromSide">
      <label id="wr-distanceFromSideUnitLabel" class="input-unit">{{ this.lengthUnit }}</label>
    </div>
    <div class="row-element">
      <label>Length</label>
      <input id="wr-lengthLabel" class="input-text" v-model="length">
      <label id="wr-lengthUnitLabel" class="input-unit">{{ this.lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
  </div>
</template>
  
<script>
import { useSprinklerStore } from '@/store/SprinklerStore';
import { useSettingsStore } from '@/store/SettingsStore';

export default {
  computed: {
    lengthUnit () {
      return useSettingsStore().lengthUnit;
    },
    distFromEnd: {
      get () {
        return useSprinklerStore().distFromEnd;
      },
      set (value) {
        useSprinklerStore().distFromEnd = value;
      }
    },
    distFromSide: {
      get () {
        return useSprinklerStore().distFromSide;
      },
      set (value) {
        useSprinklerStore().distFromSide = value;
      }
    },
    width: {
      get () {
        return useSprinklerStore().wettedWidth;
      },
      set (value) {
        useSprinklerStore().wettedWidth = value;
      }
    },
    length: {
      get () {
        return useSprinklerStore().wettedLength;
      },
      set (value) {
        useSprinklerStore().wettedLength = value;
      }
    },
  },
};
</script>

<style scoped>
label {
  margin-left: 10px;
  flex:1;
}
.unit-label {
  text-align: center;
  flex: 0;
}
</style>