<template>
  <div class="print-background no-print" ref="printBackground" v-if="getPrintIsOpen" @click="handleClose">
    <div ref="printPopup" class="print-popup-holder no-print" @click.prevent="handlePrevent" id="printPopup">
      <div ref="printHeader" class="print-header no-print">
        <div class="print-header-text">Print</div>
        <div class="print-inputs">
          <div class="print-input-title-text">Job Title</div>
          <input class="print-job-title-input" id="pr-title" type="text" v-model="getJobTitle"
            @input="handleJobTitleInput" />
          <div class="print-input-notes-btn" id="pr-notes-btn" @click="handleNotes">{{ getNotesState }}</div>
        </div>
        <div class="print-input-notes-text" v-if="getNotesIsShowing">
          <textarea class="print-input-text" id="pr-notes" @input="handleJobNotesInput"
            placeholder="Enter notes here..."> {{ getJobNotes }}</textarea>
        </div>
      </div>
      <div ref="printDisplay" class="print-display" @click="handlePrintSheet" id="printDisplay">
        <div class="page-boundary" ref="pageBoundary">
          <div class="print-sheet-holder print-pages" ref="printPages" :style="getDisplayPage">
            <PrintSheet v-for="(record, index) in getPrintQueue" :key="index" :record="record"></PrintSheet>
          </div>
        </div>
      </div>
      <div ref="printFooter" class="print-footer no-print">
        <div class="print-footer-holder">
          <div class="pagination" v-if="isMoreThanOnePage">
            <div @click="printBack" class="print-direction-btn" :class="getBackState">BACK</div>
            <div class="printPageRange"> {{ getCurrentPrintPage }} of {{ getTotalPrintPages }} </div>
            <div @click="printAdvance" class="print-direction-btn" :class="getNextState">NEXT</div>
          </div>
          <div class="print-button-group">
            <div class="print-button-header">Paper Size</div>
            <div class="print-option-button" id="pr-letter-btn" :class="getSize('letter')" @click="setSize('letter')">
              Letter</div>
            <div class="print-option-button" id="pr-a4-button" :class="getSize('a4')" @click="setSize('a4')">A4</div>
          </div>
          <div class="print-button-group">
            <div class="print-button-header">Page Orientation</div>
            <div class="print-option-button" id="pr-portrait-btn" :class="getOrientation('portrait')"
              @click="setOrientation('portrait')">Portrait</div>
            <div class="print-option-button" id="pr-landscape-btn" :class="getOrientation('landscape')"
              @click="setOrientation('landscape')">Landscape</div>
          </div>
          <div class="print-button-group">
            <div class="cancel-button" id="pr-cancel-btn" @click="handleCancel">Cancel</div>
            <div class="print-button" id="pr-print-btn" @click="handlePrint">PRINT</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {usePrintStore} from '@/store/PrintStore';
import PrintSheet from '@/components/print/PrintSheet';

export default {
  name: 'PrintPopup',
  components: {
    PrintSheet
  },
  data () {
    return {
      notesIsShowing: 0,
      isZoomed: false
    };
  },
  setup () {
    const style = document.createElement('style');
    return { style };
  },
  mounted () {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('afterprint', this.revertLayout);

    // create @page media
    document.head.appendChild(this.style);
    this.updatePaper();
  },
  computed: {
    getPrintIsOpen () {
      return usePrintStore().getPrintIsOpen;
    },
    getNotesIsShowing () {
      return this.notesIsShowing;
    },
    getJobTitle () {
      return usePrintStore().getJobTitle;
    },
    getJobNotes () {
      return usePrintStore().getJobNotes;
    },
    getNotesState () {
      return (!this.$data.notesIsShowing) ? 'Show Notes' : 'Hide Notes';
    },
    getCurrentPrintPage () {
      return usePrintStore().getCurrentPrintPage + 1;
    },
    getTotalPrintPages () {
      return usePrintStore().getTotalPrintPages;
    },
    getPrintQueue () {
      return usePrintStore().getPrintQueue;
    },
    getDisplayPage () {
      let calc = usePrintStore().getCurrentPrintPage * -usePrintStore().getDimensions.width;
      return 'transform: translateX(' + calc + 'px);';
    },
    getBackState () {
      return (usePrintStore().getCurrentPrintPage === 0) ? 'disabled': '';
    },
    getNextState () {
      return (usePrintStore().getCurrentPrintPage + 1 === usePrintStore().getTotalPrintPages) ? 'disabled': '';
    },
    isMoreThanOnePage () {
      return usePrintStore().getTotalPrintPages > 1;
    }
  },
  updated () {
    this.handleResize();
  },
  methods: {
    handleJobTitleInput (e) {
      usePrintStore().setJobTitle(e.target.value);
    },
    handleJobNotesInput (e) {
      usePrintStore().setJobNotes(e.target.value);
    },
    handleNotes () {
      this.$data.notesIsShowing = !this.$data.notesIsShowing;
      document.body.addEventListener('click', this.handleNotesHide);
    },
    handleNotesHide () {
      this.$data.notesIsShowing = false;
      document.body.removeEventListener('click', this.handleNotesHide);
    },
    getSize (type) {
      return (usePrintStore().getSize === type) ? 'print-option-selected' : '';
    },
    getOrientation (type) {
      return (usePrintStore().getOrientation === type) ? 'print-option-selected' : '';
    },
    setSize (type) {
      usePrintStore().setSize(type);
    },
    setOrientation (type) {
      usePrintStore().setOrientation(type);
    },
    handleClose () {
      usePrintStore().setPrintIsOpen(false);
    },
    handlePrevent (e) {
      e.stopImmediatePropagation();
    },
    updatePaper () {
      this.style.innerHTML = '@page {size:' + usePrintStore().getSize + ' ' + usePrintStore().getOrientation + ';margin: 4mm 4mm 4mm 4mm;}';
    },
    printBack () {
      if (usePrintStore().getCurrentPrintPage === 0) { return; }
      usePrintStore().showPrintPage(-1);
    },
    printAdvance () {
      if (usePrintStore().getCurrentPrintPage + 1 === usePrintStore().getTotalPrintPages) { return; }
      usePrintStore().showPrintPage(1);
    },
    handlePrint () {
      this.updatePaper();
      this.$refs.printBackground.style.backgroundColor = 'white';
      this.$refs.pageBoundary.style.transform = 'scale(' + 1 + ')';
      this.$refs.pageBoundary.style.overflow = 'initial';
      document.body.style.overflow = 'initial';
      document.body.prepend(this.$refs.pageBoundary);
      window.print();
    },
    handlePrintSheet () {
      if (!this.$data.isZoomed) {
        this.$refs.printPopup.classList.add('print-zoom');
        this.$refs.printHeader.style.display =
        this.$refs.printFooter.style.display = 'none';
      } else {
        this.$refs.printPopup.classList.remove('print-zoom');
        this.$refs.printHeader.style.display =
        this.$refs.printFooter.style.display = 'flex';
      }
      this.$data.isZoomed = !this.$data.isZoomed;
      this.handleResize();
    },
    revertLayout () {
      this.$refs.printBackground.style.backgroundColor = 'rgba(0, 0, 0, 0.60)';
      this.$refs.pageBoundary.style.transform = 'scale(' + usePrintStore().getPageScale + ')';
      this.$refs.pageBoundary.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      this.$refs.printDisplay.prepend(this.$refs.pageBoundary);
    },
    handleResize () {
      let popup = this.$refs.printPopup;
      let page = this.$refs.pageBoundary;
      if (popup === null || page === undefined) { return; }
      if (usePrintStore().getSize === 'a4' && usePrintStore().getOrientation === 'landscape') {
        document.body.classList.add('zoom-75');
      } else {
        document.body.classList.remove('zoom-75');
      }
    },
    handleCancel () {
      this.handleClose();
    }
  }
};
</script>

<style>
@media print {
  .no-print {
    display: none !important;
  }
  body {
    zoom: 80% !important;
  }
  .zoom-75 {
    zoom: 75% !important;
  }
  .print-background {
    width: 100% !important;
    height: 100% !important;
  }
  .print-popup-holder {
    width: 100% !important;
    height: 100% !important;
  }
  .page-boundary {
    border: none !important;
    flex-direction: column;
    width: 100% !important;
    height: 100% !important;
  }
  .print-pages {
    display: flex;
    flex-direction: column;
  }
}
.page-boundary * {
  -webkit-print-color-adjust: exact !important;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.printPageRange {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 85%;
}
</style>

<style scoped>
.print-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.60);
  display: flex;
  align-items: center;
  justify-content: center;
}
.print-popup-holder {
  width: 90vw;
  height: 90vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.print-zoom {
  width: 100vw;
  height: 100vh;
}
.print-header {
  height: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #e0e0e0;
}
.print-display {
  width: 100%;
  overflow: hidden;
}
.print-footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}
.page-boundary {
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}
.print-button-group {
  display: flex;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
}
.print-button-header {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 85%;
}
.print-option-button {
  padding: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 85%;
}
.print-option-selected {
  background-color: rgba(0, 84, 178, 0.17);
}
.cancel-button {
  cursor: pointer;
  font-size: 80%;
  padding-right: 20px;
}
.print-button {
  padding: 8px 33px;
  user-select: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 90%;
  cursor: pointer;
  background-color: #00bd5e;
}
.print-header-text {
  font-weight: 700;
  align-self: center;
  margin-left: 20px;
}
.print-inputs {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 50px;
}
.print-input-title-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-weight: 600;
  font-size: 85%;
}
.print-job-title-input {
  height: 20px;
  align-self: center;
}
.print-input-notes-btn {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-size: 85%;
}
.print-input-notes-text {
  position: absolute;
  right: 0;
  margin-top: 50px;
  margin-right: 5vw;
  z-index: 1000;
  background-color: #00bd5e;
}
.print-input-text {
  width: 25vw;
  height: 15vh;
  margin: 20px;
  text-align: start;
  justify-content: start;
  font-family: "Heebo", sans-serif;
}
.print-footer-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.print-pages {
  display: flex;
}
.disabled {
  opacity: .5;
  cursor: default;
}
.print-direction-btn {
  font-weight: 600;
  font-size: 85%;
  cursor: pointer;
}
</style>