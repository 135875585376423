<template>
  <div class="settings-overlay-container">
    <div class="settings-overlay-card">
      <div class="settings-overlay-header">
        <h4 class="settings-overlay-title">Settings</h4>
        <div class="settings-overlay-exit">
          <button class="settings-overlay-logout-btn" @click="this.handleLogout">Logout</button>
          <div class="badge" @click="handleClose">
            <label>{{ this.user.initials }}</label>
          </div>
        </div>
      </div>
      <div class="settings-overlay-buttons-container">
        <div class="settings-overlay-buttons-box">
          <NUIButton :obj="this.units" :displayType="'icon'" :isSelected="appFunction === 'units'">
          </NUIButton>
          <NUIButton :obj="this.pass" :displayType="'icon'" :isSelected="appFunction === 'password'">
          </NUIButton>
        </div>
      </div>
      <div class="settings-overlay-content">
        <Units v-if="isUnits" />
        <PassRedeem v-if="isPassRedeem" />
      </div>
    </div>
  </div>
</template>

<script>
import { useIdentityStore } from '@/store/IdentityStore';
import NUIButton from '@/components/nelsonUI/NUIButton.vue';
import Units from '@/components/settings/Units.vue';
import PassRedeem from '@/components/settings/PassRedeem.vue';
import { useModalStore } from '@/store/ModalStore';

export default {
  data () {
    return {
      isUnits: true,
      isPassRedeem: false,
      appFunction: 'units',
      units: {
        label: 'Units',
        icon: 'data',
        value: 'units',
        orientation: 'top',
        marker: 'var(--nelson-blue)',
        func: this.toggleUnitsView,
      },
      pass: {
        label: 'Password',
        icon: 'data',
        value: 'password',
        orientation: 'top',
        marker: 'var(--nelson-gray)',
        func: this.togglePassView,
      },
    };
  },
  setup () {
    useIdentityStore().getUserProperties();
  },
  components: {
    NUIButton,
    Units,
    PassRedeem,
  },
  computed: {
    user () {
      return useIdentityStore().User;
    },
  },
  methods: {
    handleLogoutStyle () {
      return {
        'background-color': 'var(--nelson-blue)',
        'width': '120px',
        'float': 'right',
      };
    },
    handleClose () {
      useModalStore().hideModal();
    },
    togglePassView () {
      if (!this.isPassRedeem) {
        this.appFunction = 'password';
        this.isUnits = this.isUnits ? false : true;
        this.isPassRedeem = this.isPassRedeem ? false : true;
      }
    },
    toggleUnitsView () {
      if (!this.isUnits) {
        this.appFunction = 'units';
        this.isUnits = this.isUnits ? false : true;
        this.isPassRedeem = this.isPassRedeem ? false : true;
      }
    },
    handleLogout () {
      useIdentityStore().signout();
    },
  }
};
</script>

<style scoped>
.settings-overlay-title {
  margin: 0;
}
.settings-overlay-container {
  background-color: #FFFFFF;
  width: 40%;
}
.settings-overlay-card {
  width: 100%;
}
.settings-overlay-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #E5E5E5;
  height: 65px;
  margin-bottom: 1rem;
}
.settings-overlay-content {
  padding: 0 2rem;
}
.settings-overlay-buttons-box {
  display: flex;
  flex-direction: row;
}
.settings-overlay-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 8px 1rem;
}
.settings-overlay-exit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.badge {
  aspect-ratio: 1/1;
  cursor: pointer;
  background-color: black;
  border-radius: 25px;
  color: white;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.settings-overlay-logout-btn {
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding-right: 1rem;
}
</style>
