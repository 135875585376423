import { defineStore } from 'pinia';
import { useIdentityStore } from '@/store/IdentityStore';

export const useGridStore = defineStore('grid', {
  state () {
    return {
      viewOptions: {
        grid: false,
        contours: true,
        radii: true,
        trees: false,
        strips: false,
      },
      lineColors: [
        {
          name: 'Black',
          color: '#000000'
        },
        {
          name: 'White',
          color: '#ffffff'
        }
      ],
      themes: [
        {
          name: 'Cubehelix',
          colors: [
            [0, 0, 0],
            [26, 21, 48],
            [22, 61, 78],
            [31, 102, 66],
            [84, 121, 47],
            [160, 121, 73],
            [208, 126, 147],
            [207, 156, 218],
            [193, 202, 243],
            [210, 238, 239],
            [255, 255, 255]
          ]          
        },
        {
          name: 'Standard',
          colors: [
            [255, 255, 255],
            [255, 248, 220],
            [222, 184, 135],
            [255, 255, 0],
            [176, 224, 230],
            [154, 205, 50],
            [44, 124, 80],
            [0, 0, 128],
          ],         
        },
        {
          name: 'RedGreen',
          colors: [
            [165, 0, 38],
            [212, 50, 44],
            [241, 110, 67],
            [252, 172, 99],
            [254, 221, 141],
            [249, 247, 174],
            [215, 238, 142],
            [164, 216, 110],
            [100, 188, 97],
            [34, 150, 79],
            [0, 104, 55]
          ]          
        },
        {
          name: 'Warm',
          colors: [
            [110, 64, 170],
            [150, 61, 179],
            [191, 60, 175],
            [228, 65, 157],
            [254, 75, 131],
            [255, 94, 99],
            [255, 120, 71],
            [251, 150, 51],
            [226, 183, 47],
            [198, 214, 60],
            [175, 240, 91]
          ]
        },
        {
          name: 'Cool',
          colors: [
            [110, 64, 170],
            [96, 84, 200],
            [76, 110, 219],
            [54, 140, 225],
            [35, 171, 216],
            [26, 199, 194],
            [29, 223, 163],
            [48, 239, 130],
            [82, 246, 103],
            [127, 246, 88],
            [175, 240, 91]
          ]
        },
        {
          name: 'Blues',
          colors: [
            [247, 251, 255],
            [227, 238, 249],
            [207, 225, 242],
            [181, 212, 233],
            [147, 195, 223],
            [109, 174, 213],
            [75, 151, 201],
            [47, 126, 188],
            [24, 100, 170],
            [10, 74, 144],
            [8, 48, 107]
          ]
        }
      ],
      scaleModes: [
        { name:'Sliding' },
        { name:'Fixed' }
      ],
      selectedLineColorIndex: 0,
      selectedThemeIndex: 2,
      selectedScaleModeIndex: 0,
      numberOfLegendValues: 10,
      fixedModeMin: 0,
      fixedModeIncrement: 0.05,

      // Pixel Conversions
      gridHeight: 0,
      gridWidth: 0,
    };
  },
  getters: {
    selectedTheme () {
      return this.themes[this.selectedThemeIndex];
    },
    selectedLineColor () {
      return this.lineColors[this.selectedLineColorIndex];
    },
    selectedScaleMode () {
      return this.scaleModes[this.selectedScaleModeIndex];
    },
    settings () {
      return (this.viewOptions.grid ? 1 : 0)
        + ':' + (this.viewOptions.contours ? 1 : 0)
        + ':' + (this.viewOptions.radii ? 1 : 0)
        + ':' + (this.viewOptions.trees ? 1 : 0)
        + ':' + (this.viewOptions.strips ? 1 : 0)
        + ':' + this.selectedLineColorIndex
        + ':' + this.selectedThemeIndex
        + ':' + this.selectedScaleModeIndex
        + ':' + this.fixedModeMin
        + ':' + this.fixedModeIncrement;
    },
    getPixelsPerFt () {
      return this.pixelPerFt;
    },
    getSelectedLineColorIndex () {
      return this.selectedLineColorIndex;
    },
    getSelectedColor () {
      return this.lineColors[this.getSelectedLineColorIndex].name.toLowerCase();
    },
    getViewOptions () {
      return this.viewOptions;
    }
  },
  actions: {
    setShowCanValues (value) {
      this.viewOptions.grid = value;
      this.storeSettings();
    },
    setShowContours (value) {
      this.viewOptions.contours = value;
      this.storeSettings();
    },
    setShowRadii (value) {
      this.viewOptions.radii = value;
      this.storeSettings();
    },
    setShowTrees (value) {
      this.viewOptions.trees = value;
      this.storeSettings();
    },
    setShowStrips (value) {
      this.viewOptions.strips = value;
      this.storeSettings();
    },
    setTheme (value) {
      this.selectedThemeIndex = this.themes.findIndex((theme) => theme.name == value);
      this.storeSettings();
    },
    setLineColor (value) {
      this.selectedLineColorIndex = this.lineColors.findIndex((color) => color.name == value);
      this.storeSettings();
    },
    setScaleMode (value) {
      this.selectedScaleModeIndex = this.scaleModes.findIndex((mode) => mode == value);
      this.storeSettings();
    },
    setFixedModeMin (value) {
      this.fixedModeMin = value;
      this.storeSettings();
    },
    setFixedModeIncrement (value) {
      this.fixedModeIncrement = value;
      this.storeSettings();
    },
    storeSettings () {
			useIdentityStore().setUserProperty({ Display: this.settings });
    },
    hexToRgb (hex) {
      hex = hex.replace(/[^0-9A-F]/gi, '');
      let bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    },
    hexFromValue (value, max, min) {
      const colors = this.selectedTheme.colors;
      const percent = Math.min(Math.max((value - min) / (max - min), 0), 1);
      var scaledPercent = percent * (colors.length - 1);
      const priorColor = colors[Math.floor(scaledPercent)];
      const nextColor = colors[Math.ceil(scaledPercent)];
      if (priorColor == undefined) { return this.hexFromRGB(colors[colors.length - 1]); }
      const color = priorColor.map((prior, index) => this.colorFromRange(scaledPercent, prior, nextColor[index]));
      return this.hexFromRGB(color);
    },
    colorFromRange (scaledPercent, priorColor, nextColor) {
      const interpolation = Math.abs(nextColor - priorColor) * (scaledPercent - Math.floor(scaledPercent));
      var color = (nextColor > priorColor) ? priorColor + interpolation : priorColor - interpolation;
      return Math.round(color);
    },
    hexFromRGB (color) {
      return '#' + this.hexFromColor(color[0]) + this.hexFromColor(color[1]) + this.hexFromColor(color[2]);
    },
    hexFromColor (color) {
      var colorHex = Math.round(color).toString(16);
      if (colorHex.length == 1) { colorHex = '0' + colorHex; }
      return colorHex;
    },
    restoreSettings () {
			const identityStore = useIdentityStore();
			var storedSettings = identityStore.User.properties.Overlap_Display;
			if (storedSettings == null) {
				identityStore.setUserProperty({ Display: this.settings });
				return;
			}
			var settings = storedSettings.split(':');
			this.viewOptions.grid = parseInt(settings[0]);
			this.viewOptions.contours = parseInt(settings[1]);
			this.viewOptions.radii = parseInt(settings[2]);
			this.viewOptions.trees = parseInt(settings[3]);
			this.viewOptions.strips = parseInt(settings[4]);
      this.selectedLineColorIndex = parseInt(settings[5]);
      this.selectedThemeIndex = parseInt(settings[6]);
      this.selectedScaleModeIndex = parseInt(settings[7]);
      this.fixedModeMin = parseFloat(settings[8]);
      this.fixedModeIncrement = parseFloat(settings[9]);
		},
  }
});