<template>
  <div class="tv-chart-values-holder">
    <div class="tvXLabels-holder">
      <div class="tvBlank"></div>
      <div v-for="(label, index) in getTVXLabels" :key="index" class="tvXLabel">
        {{ label.label }}
      </div>
    </div>
    <div class="tvYLabels-holder">
      <div v-for="(group, index) in getTVYLabels" :key="index" class="tvYRow">
        <div class="tvYLabel">{{group.group}}</div>
        <div v-for="(data, index) in group.data" :key="index" class="tvRowValue">
          {{ getTVValue(data.unit, data.value) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usePrintStore } from '@/store/PrintStore';
import { useSprinklerStore } from '@/store/SprinklerStore';
import { useSettingsStore } from '@/store/SettingsStore.js';

export default {
  name: 'TreeVineSpacingChart',
  computed: {
    getTVXLabels () {
      return usePrintStore().getTreeVinePrintData[0].data;
    },
    getTVYLabels () {
      return usePrintStore().getTreeVinePrintData;
    }
  },
  methods: {
    getTVValue (unit, val) {
      return useSettingsStore().valueFor(unit, parseFloat(useSprinklerStore().selectedRecord[val]));
    }
  }
};
</script>

<style scoped>
.tvXLabels-holder {
  width: 100%;
  display: flex;
}
.tvBlank {
  width: 85px;
  height: 30px;
}
.tvXLabel {
  flex: 1;
  height: 30px;
  border-left: .5px solid #adadad;
  font-weight: 600;
  font-size: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.tvYLabel {
  width: 85px;
  height: 23px;
  border-bottom: .5px solid #adadad;
  font-weight: 600;
  font-size: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.tvYLabels-holder {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tv-chart-values-holder {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
.tvYRow {
  display: flex;
  width: 100%;
}
.tvRowValue {
  flex: 1;
  height: 23px;
  border-bottom: .5px solid #adadad;
  border-left: .5px solid #adadad;
  font-size: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
}
</style>